<template>
  <appDialog
    customClass="confirm"
    v-bind="$attrs"
    v-on="$listeners"
    width="1000"
    height="70vh"
    title="导入确认"
    :hasBtn="false"
    :beforeOpen="onBeforeOpen"
    @submit="submit"
  >
    <template>
      <div class="content">
        <div class="count-wrapper flex-middle mb10">
          <div class="flex-one">导入总条数：{{ total }}</div>
          <div class="flex-one">可导入成功：{{ successNum }}</div>
          <div class="flex-one">导入失败：{{ failureNum }}</div>
        </div>
        <baseTable :data="data" :option="tableOption"></baseTable>
      </div>
    </template>
  </appDialog>
</template>

<script>
import { fillValidErrorPassColumnInData } from '@/utils/validate'
import { validatenull } from '@/components/avue/utils/validate'
import { supplierImport } from '@/api/order/supplier'

export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      errData: [],
      tableOption: {
        isOnePage: true,
        page: true,
        border: true,
        menu: false,
        // index: true,
        column: [
          {
            label: '大类',
            prop: 'category',
            rules: []
          },
          {
            label: '品名',
            prop: 'prototype',
            rules: []
          },
          {
            label: '尺码',
            prop: 'sizeName',
            rules: [
              {
                required: true
              }
            ]
          },
          {
            label: '款式',
            prop: 'style',
            rules: [
              {
                required: true
              }
            ]
          },
          {
            label: '供应商',
            prop: 'supplierName',
            rules: [
              {
                required: true
              }
            ]
          }
        ]
      }
    }
  },

  computed: {
    total({ data }) {
      return data.length
    },

    failureNum({ errData }) {
      return errData.length
    },

    successNum({ total, failureNum }) {
      return total - failureNum
    },

    column({ tableOption }) {
      return $GET(tableOption, 'column', [])
    }
  },

  methods: {
    async submit(data, done) {
      if (this.failureNum > 0) {
        this.$message.error('存在导入失败的数据')
        return done(false)
      }
      const res = await awaitResolveDetail(supplierImport({
        list: this.data
      }))
      done(!!res)
    },

    onBeforeOpen() {
      const res = fillValidErrorPassColumnInData(this.column, this.data)
      this.errData = res.filter(item => !validatenull(item.errors))
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.count-wrapper {
  width: 600px;
}

.content {
  height: calc(100% - 20px);
}
</style>
