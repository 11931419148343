<template>
  <div class="app-container">
    <baseTable :resetMergeData="resetMergeData" :option="tableOption" :api="getList">
      <template #menuLeft="scoped">
        <AddOrEditSupplier v-if="permissionList.hasAddSupplier" @success="init"/>
        <ExportSupplier v-if="permissionList.hasSupplierExport" :postData="postData"/>
        <ImportSupplier v-if="permissionList.hasSupplierImport" @success="init"/>
      </template>

      <template #businessLicense="{row}">
        <DefaultImg :src="row.businessLicense"></DefaultImg>
      </template>

      <template #menu="{ row }">
        <AddOrEditSupplier v-if="permissionList.hasEditSupplier" class="menu-btn-item" type="edit" :data="row" @success="init"/>
        <AssociateSupplier v-if="permissionList.hasAssociate" class="menu-btn-item" :data="row" :isEdit="true" @successHandler="init"/>
      </template>
    </baseTable>
  </div>
</template>

<script>
import baseTableMixin from '@/components/base/baseTable/mixins/baseTable'
import getBaseTableDataMixin from '@/components/base/baseTable/mixins/getBaseTableDataMixin'
import DefaultImg from '@/views/components/defaultImg'
import AddOrEditSupplier from './module/addOrEditSupplier'
import ImportSupplier from './module/importSupplier'
import ExportSupplier from './module/exportSupplier'
import AssociateSupplier from './module/associateSupplier'
import { tableOption } from './const'
import { supplierList as getList } from '@/api/order/supplier'
import { checkPermission } from '@/utils'

export default {
  components: {
    DefaultImg,
    AddOrEditSupplier,
    ImportSupplier,
    ExportSupplier,
    AssociateSupplier
  },

  mixins: [
    baseTableMixin,
    getBaseTableDataMixin({
      dataAttrs: {
        postData: {}
      }
    })
  ],

  data() {
    return {
      tableOption,
      resetMergeData: {
        orderItems: [
          {
            asc: false,
            column: 'create_time'
          }
        ]
      }
    }
  },

  computed: {
    permissionList() {
      return {
        hasAddSupplier: checkPermission(['externaladmin:supplier1:supplierList:addSupplier']),
        hasEditSupplier: checkPermission(['externaladmin:supplier1:supplierList:editSupplier']),
        hasSupplierExport: checkPermission(['externaladmin:supplier1:supplierList:supplierExport']),
        hasSupplierImport: checkPermission(['externaladmin:supplier1:supplierList:supplierImport']),
        hasAssociate: checkPermission(['externaladmin:supplier1:supplierList:associate'])
      }
    }
  },

  methods: {
    getList
  }
}
</script>

<style scoped lang="scss">
</style>
