<template>
  <span class="import-supplier-component">
    <XlsxTable @on-select-file="onSelectFile" class="inline-block">
      <el-button type="primary" size="small">导入供应商结构</el-button>
    </XlsxTable>
    <result v-bind="$attrs" :data="data" :visible.sync="visible" @success="success"></result>
  </span>
</template>

<script>
import XlsxTable from '@/components/xlsxTable.vue'
import { changeArrKey } from '@/utils'
import result from './importResult'
import { map } from 'lodash'
import { IMPORT_TRANSFORM_SUPPLIER_KEYS } from './const'

export default {
  components: { XlsxTable, result },

  data() {
    return {
      visible: false,
      data: []
    }
  },

  methods: {
    async onSelectFile(excelData) {
      let { body = [] } = excelData
      this.data = changeArrKey(body, IMPORT_TRANSFORM_SUPPLIER_KEYS)
      const [err, errData] = this.check(this.data)
      if (err) {
        this.$message.error(this.getErrMsg(errData))
        return
      }
      this.visible = true
    },

    getErrMsg(errData) {
      return map(errData, 'errMsg').join('、')
    },

    check(data) {
      const errData = []
      const isNotEmpty = checkEmpty()
      if (!isNotEmpty) return [true, errData]
      return [false, errData]

      function checkEmpty() {
        if (!data.length) {
          errData.push({
            errMsg: '上传表格无数据',
            key: null,
            index: null
          })
        }
        return data.length
      }
    },

    success() {
      this.$emit('success')
    }
  }
}
</script>

<style scoped lang="scss"></style>
