<template>
  <el-button type="primary" size="small" @click="exportHandler">
    导出供应商结构
  </el-button>
</template>

<script>
import { supplierExport } from '@/api/order/supplier'
import { exportWrap } from '@/utils'
import { REQUEST_ALL_DATA } from '@/utils/constant'
import { formatDate } from 'element-ui/src/utils/date-util'

export default {
  props: {
    postData: {
      type: Object,
      required: true
    }
  },

  methods: {
    async exportHandler() {
      await awaitLoading(
        exportWrap(
          supplierExport({
            ...this.postData,
            ...REQUEST_ALL_DATA
          }),
          `${formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss')}-供应商结构`
        )
      )
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
