<template>
  <div class="inline-block">
    <color-text-btn  :loading="loading" @click="to">关联款式</color-text-btn>

    <DistributionDialog
      type="associateStyle"
      v-model="value"
      v-if="showDistributionDialog"
      :info="data"
      :visible.sync="dialogVisible"
      :isEdit="isEdit"
      :resetMergeData="resetMergeData"
      v-on="$listeners"
    />
  </div>
</template>
<script>
import DistributionDialog from '@/views/components/distributionDialog'
import { commonEheaderMixin } from '@/mixins'

export default {
  mixins: [commonEheaderMixin],
  components: { DistributionDialog },

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    isEdit: Boolean
  },

  data() {
    return {
      loading: false,
      value: [],
      resetMergeData: {},
      showDistributionDialog: false
    }
  },

  watch: {
    dialogVisible(newVal) {
      if (newVal) return
      this.$nextTick(() => {
        this.value = []
        this.showDistributionDialog = false
      })
    }
  },

  methods: {
    async to() {
      this.showDistributionDialog = true
      if (this.isEdit) {
        this.value = $GET(this.data, 'relevanceStyleList', [])
      }
      this.$nextTick(() => {
        this.dialogVisible = true
      })
    }
  }
}
</script>
